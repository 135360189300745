<template>
  <div>
    <div>
      <div class="theme-font-regular font-16" v-html="expandedVwTxt"></div>
    </div>
    <CalendarEventsList :calendar-events-list="content.eventsResults" :limit="limit" :show-date="true" :show-error="true"></CalendarEventsList>
  </div>
</template>
<script>
export default {
  name: 'Calendar-one-column-list',
  components: {
    CalendarEventsList: () => import('./CalendarEventsList.vue'),
  },
  props: {
    content: { type: Object },
    limit: [Number, String],
    expandedVwTxt: { type: String },
  },
}
</script>
